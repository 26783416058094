import React from "react";
import ReactPlayer from "react-player";
// import useEmblaCarousel from "embla-carousel-react";

import Header from "../Components/Header";
import MovieSwiper from "../Components/MovieSwiper";

import Video1 from "../videos/mother-in-law-the-movie-trailer.mp4";
import Image01 from "../images/image-2.jpg";

import "./Home.css";
import EmblaCarousel from "../Components/EmblaCarousel";

const Home = () => {
  return (
    <>
      <Header />
      <section className="heroSection">
        <MovieSwiper />
      </section>
      <section className="featured">
          <EmblaCarousel/>
      </section>
      <section className="moviePreview">
        <div className="container">
          <ReactPlayer url={Video1} volume={0} muted={true} controls={true} />
        </div>
      </section>
      <section className="videos">
        <h3>Videos</h3>
        <div className="container">
          <div className="imageContainer">
            <img src={Image01} alt="" />
          </div>
          <div className="imageContainer">
            <img src={Image01} alt="" />
          </div>
          <div className="imageContainer">
            <img src={Image01} alt="" />
          </div>
          <div className="imageContainer">
            <img src={Image01} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;